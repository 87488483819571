import React from "react";
import KopCity from "../assets/images/projects/banner.jpg";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function OurProjects(props) {
  let { id } = useParams();

  // const queryParams = new URLSearchParams(window.location.search);
  // const urlid = queryParams.get('id');
  // console.log(urlid);
  // alert(urlid);

  // console.log(id);
  const [data, setData] = useState([]);
  // alert(id);

  // const fetchDetails = ()=>{
  //     axios.get("getProjects?id=" + id)
  //     .then((res) => setData(res.data))

  // }
  // const fetchDetails = ()=>{
  //     axios.get("getProjects?id=" + id)
  //     .then((res) => setData(res.data))

  // }

  // useEffect(() => {
  //     fetchDetails();
  // }, [])

  useEffect(() => {
    const response = axios.get("getProjects", {
      params: { id: id },
    });
    response.then((res) => {
      setData(res.data);
    });
    // console.log("result", data);
  }, [id]);

  // window.addEventListener("beforeunload", (event) => {
  //     fetchDetails();
  //     console.log("API call before page reload");
  // });

  // window.addEventListener("unload", (event) => {
  //     fetchDetails();
  //     console.log("API call after page reload");
  // });

  return (
    <>
      <div className="bodyWrapper">
        <Header />
        <section className="bannerImg">
          <img src={KopCity} alt="SAMARTH SECURITY SYSTEMS" />
        </section>
        <section className="pageContent">
          <div className="wrap">
            {data.map((item) => (
              <>
                <div key={item.id}>
                  <h1 className="pageHeading">{item.title}</h1>
                  <div className="content">
                    <p>{item.description}</p>
                    <div className="gallery">
                      {item.project_images.map((project_images) => (
                        <div style={{ width:"50%", float:"left" }}>
                        <img
                          src={
                            "https://api.jan-kalyan.org/public/admin/images/project/" +
                            project_images.project_image
                          }
                          alt={item.title} style={{ width:"400px" }}
                        />
                        </div>
                      ))}

                     

                      <div
                        dangerouslySetInnerHTML={{ __html: item.other_details }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default OurProjects;
