import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/smarthsec-logo.png";
import { useEffect, useState } from "react";
import axios from "axios";

function Header() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("getProjectCategoryAndSubCategory")
      .then((res) => setData(res.data));
  }, []);



  const handleClickScroll = () => {
    const element = document.getElementById("1");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="mainheader">
        <div className="top-nav">
          <ul style={{ float: "left", width: "50%" }}>
            <li>
              <span
                className="fa fa-phone"
                style={{ marginRight: "5px" }}
              ></span>{" "}
              91-22-27622 754/59
            </li>
            <li>
              <span
                className="fa fa-envelope"
                style={{ marginRight: "5px" }}
              ></span>
              sales@samarthsec.com
            </li>
          </ul>
          <ul style={{ float: "right", width: "30%", listStyleType: "inline" }}>
            <li style={{ borderRight: "none" }}>
              <Link to="https://www.facebook.com/samarthsec" target="_blank">
                <i className="fa fa-facebook"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link to="https://twitter.com/SamarthSec" target="_blank">
                <i className="fa fa-twitter"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link
                to="https://www.linkedin.com/in/yashwant-patil-samarth-security-systems-india-pvt-ltd"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </Link>
            </li>
            <li style={{ borderRight: "none" }}>
              <Link
                to="https://instagram.com/samarthsecuritysystems?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="menubar">
        <Link to="/" id="logo">
          <img src={Logo} alt="SAMARTH SECURITY SYSTEMS" />
        </Link>
        <nav>
          <label htmlFor="drop" className="toggle">
            &#8801; Menu
          </label>
          <input type="checkbox" id="drop" />
          <ul className="menu">
            <li>
              <Link to="/"> Home </Link>
            </li>
            <li>
              <label htmlFor="drop-7" className="toggle">
                About Us +
              </label>
              <Link to="#">About Us</Link>
              <input type="checkbox" id="drop-7" />
              <ul>
                <li>
                  <Link to="/CompanyProfile">Company Profile</Link>
                </li>
                <li>
                  <Link to="/LeadByExample">Leading By Example</Link>
                </li>
                <li>
                  <Link to="/Testimonials">Testimonials</Link>
                </li>
              </ul>
            </li>

            <li>
              <label htmlFor="drop-8" className="toggle">
                Products +
              </label>
              <Link to="#">Products</Link>
              <input type="checkbox" id="drop-8" />
              <ul>
                <li>
                  <Link to="/Products">Covid-19 Products</Link>{" "}
                </li>
                <li>
                  <Link to="/Products#2">Hand-Held Metal Detectors</Link>{" "}
                </li>
                <li>
                  <Link to="/Products">
                    Walk-Through Metal Detectors (Multi-Zone)
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/Products">
                    Walk-Through Metal Detectors (Single Zone)
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/Products">Other Products</Link>{" "}
                </li>
              </ul>
            </li>

            {/* <li> 
      
      <label for="drop-1" className="toggle">Prime Project +</label>
      <a href="#">Prime Project</a>
      <input type="checkbox" id="drop-1"/>
      <ul>
     
       <li> 
          
          <label for="drop-2" className="toggle">Safe City Project +</label>
          <Link to="#">Safe City Project </Link>
          <input type="checkbox" id="drop-2"/>
          <ul>
          <li><Link to="/SafeCityKolhapur">Kolhapur safe city project</Link></li>
                                <li><Link to="/SafeCityCidco">Cidco safe city project</Link></li>
                                <li><Link to="/SafeCityNanded">Nanded safe city project</Link></li>
                                <li><Link to="/SafeCityWardha">Wardha safe city project</Link></li>
           
          </ul>
        </li>
        <li> 
          
          <label for="drop-3" className="toggle">Our Prime Project +</label>
          <Link to="#">Our Prime Project </Link>
          <input type="checkbox" id="drop-3"/>
          <ul>
          <li><Link to="/Vidhanbhavan">VidhanBhavan</Link></li>
                                <li><Link to="/MumbaiPort">Mumbai Port Trust</Link></li>
                                <li><Link to="/NammaMetro">Namma Metro</Link></li>
                                <li><Link to="/TataGroup">TATA Group</Link></li>
                                <li><Link to="/ItcHotel">ITC Hotel Group</Link></li>
          </ul>
        </li>
      </ul>
    </li> */}

            <li>
              <label htmlFor="drop-9" className="toggle">
                Prime Project +
              </label>
              <Link to="#">Prime Project</Link>
              <input type="checkbox" id="drop-9" />
              <ul>
                {data.map((item) => (
                  <li key={item.id}>
                    <label htmlFor={"drop-" + item.id} className="toggle">
                      {item.category} +
                    </label>
                    <Link to="#">{item.category}</Link>
                    <input type="checkbox" id={"drop-" + item.id} />
                    <ul>
                      {item.sub_category.map((sub_category) => (
                        <li key={sub_category.id}>
                          <Link to={"/OurProjects/" + sub_category.id}>
                            {sub_category.sub_category}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <Link to="/OurClients">Clients</Link>
            </li>
            <li>
              <Link to="/Services">Services</Link>
            </li>
            <li>
              <Link to="/OurNews">News</Link>
            </li>
            <li>
              <Link to="/ContactUs">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
