import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompanyProfile from './component/CompanyProfile';
import LeadByExample from './component/LeadByExample';
import Products from './component/Products';
import SafeCityKolhapur from './component/SafeCityKolhapur';
import SafeCityCidco from './component/SafeCityCidco';
import SafeCityNanded from './component/SafeCityNanded';
import SafeCityWardha from './component/SafeCityWardha';
import Vidhanbhavan from './component/Vidhanbhavan';
import MumbaiPort from './component/MumbaiPort';
import NammaMetro from './component/NammaMetro';
import TataGroup from './component/TataGroup';
import ItcHotel from './component/ItcHotel';
import OurClients from './component/OurClients';
import Services from './component/Services';
import OurNews from './component/OurNews';
import Test from './component/Test';
import Testimonials from './component/Testimonials';
import Home from './component/Home';
import ContactUs from './component/ContactUs'
import OurProjects from './component/OurProjects'
// import Test2 from './component/Test2';
import axios from 'axios';
// import Slider2 from './component/Slider2';


axios.defaults.baseURL = 'https://api.jan-kalyan.org/public/api/';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <React.StrictMode>

  
    <App />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/CompanyProfile" element={<CompanyProfile />} />
        <Route path="/LeadByExample" element={<LeadByExample />} />
        <Route path="/Covid19Products" element={<Products />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/SafeCityKolhapur" element={<SafeCityKolhapur />} />
        <Route path="/SafeCityCidco" element={<SafeCityCidco />} />
        <Route path="/SafeCityNanded" element={<SafeCityNanded />} />
        <Route path="/SafeCityWardha" element={<SafeCityWardha />} />
        <Route path="/Vidhanbhavan" element={<Vidhanbhavan />} />
        <Route path="/MumbaiPort" element={<MumbaiPort />} />
        <Route path="/NammaMetro" element={<NammaMetro />} />
        <Route path="/TataGroup" element={<TataGroup />} />
        <Route path="/ItcHotel" element={<ItcHotel />} />
        <Route path="/OurClients" element={<OurClients />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/OurNews" element={<OurNews />} />
        <Route path="/Test" element={<Test />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/ContactUs" element={<ContactUs />} />

        <Route path="/OurProjects/:id" element={<OurProjects />} />
        {/* <Route path="/Test2" element={<Test2 />} />
        <Route path="/Slider2" element={<Slider2 />} /> */}
        
        




      </Routes>
    </Router>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
