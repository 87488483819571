import React from 'react'
import KopCity from '../assets/images/projects/banner.jpg'
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react"
import axios from 'axios';
import { useParams } from "react-router-dom";

function SafeCityWardha() {

    
    const [data, setData] = useState([])
    
const fetchDetails = ()=>{
    axios.get("getProjects?id=" + 4)
    .then((res) => setData(res.data))
  
} 
// const fetchDetails = ()=>{
//     axios.get("getProjects?id=" + id)
//     .then((res) => setData(res.data))

// } 

useEffect(() => {
    fetchDetails();
}, [])


    return (
        <>
      
        <div className="bodyWrapper">
            <Header />
            <section className="bannerImg">
                <img src={KopCity} alt="about banner" />
            </section>
            <section className="pageContent">
                <div className="wrap">
                    {data.map((item) =>
                        <>
                            <div key={item.id}>
                                <h1 className="pageHeading">{item.title}</h1>
                                <div className="content">
  
                                    <p>{item.description}</p>
                                    <div className="gallery">
                                        <img src={"https://api.jan-kalyan.org/public/admin/images/project/" + item.project_image} />
  
                                        <div>
                                            {item.other_details}
                                        </div>
                                    </div>
                                </div>
                            </div>
  
                        </>
                    )}
  
  
                </div>
            </section>
            <Footer />
        </div>
        </>
    )
}

export default SafeCityWardha
