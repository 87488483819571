import React from 'react'
import KopCity from '../assets/images/contact/contact.jpg'
import Header from "./Header";
import Footer from "./Footer";

function Services() {

    

    return (
        <div className="bodyWrapper">
            <Header />
            <section className="bannerImg">
                <img src={KopCity} alt="SAMARTH SECURITY SYSTEMS" />
            </section>
            <section className="pageContent">
                <div className="wrap"> <h1 className="pageHeading">CONTACT US</h1>
                    <div className="row ">
                        <div className="col-lg-12 aos-init aos-animate ">
                            <div className="content contactCont">
                                <p>Samarth Security System (India) Pvt. Ltd.</p>
                                <p>Office: Plot No. A-120, M.I.D.C. Khairane, TTC Industrial Area, Thane-Belapur Road, Navi Mumbai, Maharashtra 400710</p>
                                <ul>
                                    <li>
                                        <label>Email: </label>
                                        <div className="textCont email"><span>support@samarthsec.com</span> <span>sales@samarthsec.com</span> <span>yash@samarthsec.com</span>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Tel:</label>
                                        <div className="textCont">91-22-27622 754/59
                                        </div>
                                        <label>Web:</label>
                                        <div className="textCont">www.samarthsec.com
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className="col-lg-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div className="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.2671405764927!2d73.02121461490145!3d19.095933087076506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb9566e704c845017!2sSamarth+Security+Systems+India+Pvt.+Ltd.!5e0!3m2!1sen!2sin!4v1542619739144" style={{ border: "0px", width: "100%", height: "600" }}></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Services
