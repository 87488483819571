import React from "react";
import { Button, Modal } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import CovidBanner from "../assets/images/products/banner.jpg";
import axios from "axios";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      HeadingTitle: "",
      img: "",
      Info: "",
    };
    this.state = {
      items: [],
    };
  }

  handleClick(title, img, info) {
    this.setState({ show: !this.state.show });
    this.setState({ HeadingTitle: title });
    this.setState({ img: img });
    this.setState({ Info: info });
  }
  componentDidMount() {
    axios.get("getProductDetails").then((response) => {
      console.log(response.data);
      this.setState({
        items: response.data,
      });
    });
  }

  render() {
    const { items } = this.state;
    return (
      <div className="bodyWrapper">
        <Modal
          style={{ textAlign: "center" }}
          show={this.state.show}
          onHide={() => this.handleClick()}
        >
          <Modal.Header closeButton>
            <h2>{this.state.HeadingTitle}</h2>{" "}
          </Modal.Header>
          <Modal.Body>
            <img src={this.state.img} alt="" style={{ width: "100%" }} />
          </Modal.Body>
          <Modal.Footer>
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              {this.state.Info}
            </p>
            <Button
              style={{
                backgroundColor: "red",
                marginTop: "10px",
                padding: "5px",
                color: "#fff",
              }}
              onClick={() => this.handleClick()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Header />
        <section className="bannerImg">
          <img src={CovidBanner} alt="Client banner" />
        </section>
        <div className="overlay"></div>

        <section className="pageContent">
          <div className="wrap">
            <h1 className="pageHeading">OUR PRODUCTS</h1>
            <div className="content productsSec">
              {items.map((item) => (
                <div className="productGallery" key={item.id} id={item.id}>
                  <h2 className="headStyle">{item.product_category}</h2>
                  {item.description ? (
                    <div className="content">
                      <p>{item.description}</p>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <div className="productsWrap">
                    <ul>
                      {item.products.map((products) => (
                        <>
                          <li key={products.id}>
                            <div className="imgsection">
                              <img
                                style={{ width: "172px", height: "265px" }}
                                src={
                                  "https://api.jan-kalyan.org/public/admin/images/product/" +
                                  products.product_image
                                }
                                alt={products.title}
                                onClick={() =>
                                  this.handleClick(
                                    products.title,
                                    "https://api.jan-kalyan.org/public/admin/images/product/" +
                                      products.product_image,
                                    products.description
                                  )
                                }
                              />
                            </div>
                            {products.pdf ? (
                              <span
                                className="btn-1"
                                onClick={() =>
                                  this.handleClick(
                                    products.title,
                                    "https://api.jan-kalyan.org/public/admin/images/product/" +
                                      products.product_image,
                                    products.description
                                  )
                                }
                              >
                                {products.short_title}
                                <a
                                  href={
                                    "https://api.jan-kalyan.org/public/admin/images/product/" +
                                    products.pdf
                                  }
                                  target="_blank"
                                  className="sideArrow"
                                ></a>
                              </span>
                            ) : (
                              <span> <a
                                className="btn-1"
                                onClick={() =>
                                  this.handleClick(
                                    products.title,
                                    "https://api.jan-kalyan.org/public/admin/images/product/" +
                                      products.product_image,
                                    products.description
                                  )
                                }
                              >
                                {products.short_title}
                              </a>
                              </span>
                            )}
                          </li>
                        </>
                      ))}
                    </ul>
                    {item.url ? (
                      <a className="readMore" href={item.url} target="_blank">
                      {" "}
                      View PDF
                    </a>
                    
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Products;
