import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from "react"
import axios from 'axios';

function Slider() {

  const [data, setData] = useState([])

  useEffect(() => {
    axios.get("getSliders")
    .then((res) => setData(res.data));
  
  }, [])

  return (

    <Carousel  autoPlay={true} showThumbs={false} infiniteLoop>
       {
          data.map((item) =>

          <div key={item.id}>
        <img src={"https://api.jan-kalyan.org/public/admin/images/slider/" + item.slider_image}  alt={item.description}/>
       
    </div>

           
          )
        } 
    
   
</Carousel>

  




  )
}

export default Slider
