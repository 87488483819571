import React from 'react'
import AboutBanner from '../assets/images/about-us/about-banner.jpg'
import Header from "./Header";
import Footer from "./Footer";

function LeadByExample() {
    return (
        <div>
            <Header />

            <section className="bannerImg">
                <img src={AboutBanner} alt="SAMARTH SECURITY SYSTEMS"/>
            </section>
            <section className="pageContent">
                <div className="wrap">
                    <h1 className="pageHeading">Leading By Example</h1>
                    <div className="content">
                        <p>SSSIPL is a success story written by Mr. Yashwant V. Patil, a first generation entrepreneur who believes in leading by example. His valuable contributions have been instrumental in creating a conducive and customer-friendly environment not only for our company but also for the entire industry. The meteoric rise of SSSIPL is undoubtedly a living testimony of his visionary thinking and unparalleled business acumen. Under his leadership, Samarth Security has partnered with technology leaders like ECIL- Rapiscan, Safran, Infinova, NICE (now Qognify), Axis, Allied Telesis, Honeywell, Huawei, Wipro, Siemens to deliver safe & smart city video surveillance services and solutions across Maharashtra.    </p>
                        <p>Today, SSSIPL is India's leading manufacturer of Walk-through Metal Detectors. The company has several national and international patents to its credit and has become a reputed brand when it comes to Integrated Security Systems Infrastructure. The goodwill earned over the years has helped SSSIPL cater to over 2500 clients and extend its export operations to markets in the Asia Pacific Region, the Middle East and Africa. As a part of our founder’s vision for a safer and peaceful environment, SSSIPL has vested its interests in developing products and solutions to upgrade the safety and security of Indian citizens. </p>
                        <p>Apart from being a proud recipient of several industry awards and recognitions, Yashwant V. Patil is also a known name when it comes to social responsibility. The Samarth Foundation is a stunning example of his sincere contributions to the society. The foundation supports the noble cause of educating the underprivileged children of rural India and helping the needy. His inclination towards philanthropy also encourages him to motivate upcoming sports persons and social workers by way of honoring them for their noteworthy contributions.</p>
                    </div>
                </div>
            </section>



            <Footer />
        </div>
    )
}

export default LeadByExample
