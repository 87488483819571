import React from 'react'
import KopCity from '../assets/images/services/banner1.jpg'
import Header from "./Header";
import Footer from "./Footer";

function Services() {
    return (
        <div className="bodyWrapper">
            <Header />
            <section className="bannerImg">
                <img src={KopCity} alt="about banner" />
            </section>
            <section className="pageContent">
                <div className="wrap">
                    <h1 className="pageHeading">Services</h1>
                    <div className="content">
                        <h2>ISO 9001 : 2015 CERTIFIED COMPANY</h2>
                        <p>Samarth Securities is broadly divided into three strategic business units. They include Design & Manufacturing, System Integration, Security Equipment and Threat Management. Nearly two-thirds of the company’s turnover accrues from product lines that enjoy a leadership position in the market. Currently, Samarth Securities offers a wide range of products encompassing Searching & Screening Equipment, Law Enforcement Equipment and Safety Equipment. Apart from the above offerings, the company also undertakes turnkey projects, right from concept to commissioning. Samarth Securities Systems India Pvt. Ltd. (SSSIPL) is also an established player in the exports segment. The company exports its wide range of products to Southeast Asian and African countries including emerging markets.</p>
                        <p>SSSIPL is an ISO 9001:2015 certified organisation and has the expertise and capability to address all the segments of security industry. Right from complex industrial security solutions to corporate security requirements, SSIPL has what it takes to be a thoroughbred player across categories. The company’s Metal Detector Technology bears an International Patent registration for its unique design, clearly giving it a unique positioning among the country’s leading brands in this category. The company designs and manufactures all types of metal detectors and visual search devices as per BIS, IS and NIJ -602.1 specifications. SSIPL’s in-house R&D Division makes it happen with unmatched credibility.  </p>
                        <p> Being a frontrunner in its class, SSSIPL has always raised the bar when it comes to developing new products & features. Through continuous improvement, sourcing of quality raw materials and superior manufacturing processes, the company ensures a consistent supply of products and services to its ever growing list of satisfied clients. For SSSIPL, customer satisfaction always comes first. The company believes in setting standards and elevating expectations with its ever evolving range of products and services. </p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Services
