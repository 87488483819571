import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "./Slider";
import Header from "./Header";
import Footer from "./Footer";
import Awards from "./Awards";
import HomeClients from "./HomeClients";
import HomeProjects from './HomeProjects';

function Home() {
    return (
        <div>
            <div className="bodyWrapper">
                <Header />
                <section className="bannerImg">
                    <Slider />
                </section>
                <section className="ourservices">
                    <div className="wrap">

                        <ul className="animateElement">
                            <li>
                                <div className="imgContainer"><img src="images/service-icon-1.png" alt="SAMARTH SECURITY SYSTEMS" /></div>
                                <p>Security Equipements</p>
                            </li>
                            <li>
                                <div className="imgContainer"><img src="images/service-icon-2.png" alt="SAMARTH SECURITY SYSTEMS" /></div>
                                <p>Awards and Accolades</p>
                            </li>
                            <li>
                                <div className="imgContainer"><img src="images/service-icon-3.png" alt="SAMARTH SECURITY SYSTEMS" /></div>
                                <p>ISO Certified 9001 : 2015</p>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="products">
                    <div className="secImg">
                        <img className="hideMob" src="images/product-section-img.jpg" alt="Product section" />
                        <div className="feature-content">
                            <h2>Exceeding Expectations</h2>
                            <p>SSSIPL has always been a name synonymous with excellence in the security and surveillance domain. Over the years, we have won the trust of several government organisations, corporate houses, MNCs and Public/Private Enterprises for our  commendable  contributions  towards  the industry.  </p>
                        </div>
                        <div className="features">
                            <ul>
                                <li><span><img src="images/journey-1.png" alt="Metal detectors installed" /></span>
                                    <p>Metal detectors installed</p>
                                </li>
                                <li><span><img src="images/journey-2.png" alt="CCTV cameras installed" /></span>
                                    <p>CCTV cameras installed</p>
                                </li>
                                <li><span><img src="images/journey-3.png" alt="Satisfied customers" /></span>
                                    <p>Satisfied customers</p>
                                </li>
                                <li><span><img src="images/journey-4.png" alt="OFC network laid & implemented" /></span>
                                    <p>OFC network laid & <br />implemented</p>
                                </li>
                                <li><span><img src="images/journey-5.png" alt="Solid experience" /></span>
                                    <p>of solid experience </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="primeprojects">
                  <HomeProjects/>
                </section>


                <Awards />


                <HomeClients />

            </div>




            <Footer />
        </div>
    )
}

export default Home
