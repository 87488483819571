import React, { Component } from 'react'
import axios from "axios"

export class Test extends Component {



    constructor(props) {

        super(props);
        this.state = {
            items: []

        };
    }


    componentDidMount() {
        let id = 1;
        axios.get("getProjects?id=" + id)
            .then((response) => {
                console.log(response.data)
                this.setState({
                    items: response.data
                });
            })
    }
    render() {
        const { items } = this.state;


        return (
            <div className="App">

                {items.map((item) =>
                    <>
                        <div key={item.id}>
                            <h1 className="pageHeading">{item.title}</h1>
                            <div className="content">

                                <p>{item.description}</p>
                                <div className="gallery">
                                    <img src={"images/projects/mumbaiport/" + item.project_image} />

                                    <div>
                                        {item.other_details}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )}





            </div>
        );
    }
}


export default Test

